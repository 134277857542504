import styled from '@emotion/styled'

export const StatusContainer = styled.div`
    border-bottom: .0625rem solid white;
    margin-bottom: 2rem;
`

export const Status = styled.span`
    display: inline-block;
    padding: 1rem 0;
    margin-right: 2rem;
    cursor: pointer;

    &:last-of-type {
        margin-right: 0;
    }

    &.active {
        color: var(--gold);
        font-weight: bold;
        border-bottom: .125rem solid var(--gold);
    }
`