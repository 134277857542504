import React from 'react'
import PropTypes from 'prop-types'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormInput from '../form-input/FormInput'

const MoneyRow = ({ item, update }) => {
    const handleOnChange = ({ target: { name, value } }) => update({ ...item, [name]: value })

    return (
        <Row>

            <Col>

                <FormInput
                    label=""
                    name="person"
                    id="person"
                    type="number"
                    onChange={handleOnChange}
                    value={item.person}
                />

            </Col>

            <Col>

                <FormInput
                    label=""
                    name="huf"
                    id="huf"
                    type="number"
                    onChange={handleOnChange}
                    value={item.huf}
                />

            </Col>

            <Col>

                <FormInput
                    label=""
                    name="eur"
                    id="eur"
                    type="number"
                    onChange={handleOnChange}
                    value={item.eur}
                />

            </Col>

        </Row>
    )
}

MoneyRow.propTypes = {
    item: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired
}

export default MoneyRow