import React, { useContext } from 'react'

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Footer, AcceptButton, DeclineButton } from "./reservationInfoFooterStyles"

import CheckCircle from '../../images/svg/circle-check.svg'
import CrossCircle from '../../images/svg/circle-cross.svg'

const ReservationInfoFooter = () => {
    const { reservation: { id, reservation_status_id }, changeReservationStatus } = useContext(ReservationAdminContext)

    const handleStatusChange = (status, confirmType) => {
        let confirmText = confirmType === 'accept' ? 'Biztosan jóváhagyja ezt a foglalást?' : 'Biztosan elutasítja ezt a foglalást?'

        if (confirm(confirmText))
        {
            changeReservationStatus(id, status)
        }
    }

    if (reservation_status_id === 4) return null

    return (
        <Footer>

            <Container fluid>

                <Row>

                    <Col xs={6} md={{span: 2, offset:4}}>
                    
                        <AcceptButton onClick={() => handleStatusChange(2, 'accept')} className="w-100" variant="success" disabled={reservation_status_id === 2}>
                            
                            <CheckCircle />

                        </AcceptButton>
                    
                    </Col>

                    <Col xs={6} md={2}>
                    
                        <DeclineButton onClick={() => handleStatusChange(3)} className="w-100" variant="danger" disabled={reservation_status_id === 3}>

                            <CrossCircle />

                        </DeclineButton>
                    
                    </Col>

                </Row>

            </Container>

        </Footer>
    )
}

export default ReservationInfoFooter