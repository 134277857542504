import React, { useContext } from 'react'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { hu } from "date-fns/locale"
import differenceInDays from 'date-fns/differenceInDays'
import isEmpty from 'lodash/isEmpty'
import { currencyFormatter } from "../../utils/helper"

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { borderStyle, grayBgStyle, RoomNumber, Icon, BottomBorder } from "./reservationInfoBodystyles"
import MailTo from '../mail-to/MailTo'

import Calendar from '../../images/svg/calendar-days.svg'
import People from '../../images/svg/people-group.svg'
import PhoneTo from '../phone-to/PhoneTo'

const ReservationInfoBody = () => {
    const {
        reservation: {
            id,
            room,
            billing,
            adults,
            children,
            pay_for_child,
            start_date,
            end_date,
            note,
            price
        }
    } = useContext(ReservationAdminContext)

    const { firstname, lastname, phone, email, address } = JSON.parse(billing)

    const startDateISO = parseISO(start_date)
    const endDateISO = parseISO(end_date)
    const startDate = format(startDateISO, 'yyyy. MMMM dd.', {locale: hu})
    const endDate = format(endDateISO, 'yyyy. MMMM dd.', {locale: hu})
    const difference = differenceInDays(endDateISO, startDateISO)

    const colProps =  {
        xs: 12,
        md: {
            span:10,
            offset:1
        },
        lg: {
            span:6,
            offset:3
        }
    }
    
    return (
        <Container fluid className="pb-5">
            
            <Row className="mb-4">

                <Col {...colProps} className="text-center">

                    <RoomNumber>
                        
                        <span>#{room}</span> szoba
                        
                    </RoomNumber>

                </Col>

            </Row>

            <Row>

                <Col {...colProps} className="py-3">
                    
                    <span className="text-white bg-dark px-3 py-2">foglalás időpontja</span>

                </Col>

                <Col {...colProps} className="py-5 d-flex align-items-center" css={borderStyle}>

                    <Icon>

                        <Calendar />

                    </Icon>

                    <Container>

                        <Row className="justify-content-around text-xs-left text-md-center">
                            
                            <Col xs={12} md={5}>{startDate}</Col>

                            <Col md={1} className="d-none d-md-block"> - </Col>

                            <Col xs={12} md={6}>{endDate} <span className="p-1 text-white bg-dark">[ {difference} nap ]</span></Col>

                        </Row>

                    </Container>

                </Col>

            </Row>

            <Row>

                <Col {...colProps} className="py-5">
                    
                    <span className="text-white bg-dark px-3 py-2">személyek száma</span>

                </Col>

                <Col {...colProps} className="py-1 d-flex align-items-center">

                    <BottomBorder className="d-inline-flex align-items-center">

                        <Icon>

                            <People />

                        </Icon>

                        <span className="px-3 py-1 text-white bg-dark">{adults + children} fő</span>

                    </BottomBorder>

                </Col>

                <Col {...colProps} className="py-1 d-flex align-items-center">

                    <span className="text-white bg-dark px-2 py-1 mr-2">{adults}</span> felnőtt

                </Col>

                <Col {...colProps} className="py-1 d-flex align-items-center">
                    
                    <span className="text-white bg-dark px-2 py-1 mr-2">{children}</span> gyerek
                    
                </Col>

                <Col {...colProps} className="pt-1 pb-5 d-flex align-items-center" css={borderStyle}>
                    
                    { pay_for_child !== 0 && <span className="px-2 py-1 bg-warning">{pay_for_child} gyerek után fizetni</span> }
                    
                </Col>

            </Row>

            <Row>

                <Col {...colProps} className="py-5">
                    
                    <span className="text-white bg-dark px-3 py-2">számlázási adatok</span>

                </Col>

                <Col {...colProps} className="py-1 mb-1">{lastname} {firstname}</Col>

                <Col {...colProps} className="py-1 mb-1">{address}</Col>

                <Col {...colProps} className="py-1 mb-1">
                    
                    <MailTo email={email} variant="outline-info">{email}</MailTo>
                    
                </Col>

                <Col {...colProps} className="pt-1 pb-5" css={borderStyle}>

                    <PhoneTo phone={phone} variant="outline-info">{phone}</PhoneTo>

                </Col>

            </Row>

            { !isEmpty(note) && (
                <Row>
            
                    <Col {...colProps} className="py-5">
                        
                        <span className="text-white bg-dark px-3 py-2">Megjegyzés</span>
                        
                    </Col>

                    <Col {...colProps} css={borderStyle} className="pt-1 pb-5">{note}</Col>

                </Row>
            ) }

             <Row className="mb-5">

                <Col xs={{span:8, offset:0}} md={{span:6, offset:1}} lg={{span:4, offset: 3}} className="text-right py-4" css={grayBgStyle}>Végösszeg</Col>

                <Col xs={4} lg={2} className="text-right py-4 bg-warning text-dark font-weight-bolder">{currencyFormatter(price, 'huf')}</Col>

             </Row>

        </Container>
    )
}

export default ReservationInfoBody