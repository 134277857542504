import styled from '@emotion/styled'

export const ReservationInfoContainer = styled.section`
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow-x: hidden;
    overflow-y: scroll;
`