import styled from '@emotion/styled'

import Form from 'react-bootstrap/Form'

export const LoginPage = styled.div`
    width: 55%;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2.5rem;
`

export const Title = styled.h4`
    text-align: center;
    border-bottom: .5rem solid var(--light-gray);
    padding: 1.5rem;
    background-color: var(--gold);
    color: white;
    border-radius: 2.5rem 2.5rem 0 0;
`

export const FormContainer = styled(Form)`
    padding: 1.5rem;
    border-radius: 0 0 2.5rem 2.5rem;
`