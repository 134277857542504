import React, { useContext } from 'react'
import getYear from 'date-fns/getYear'
import parseISO from 'date-fns/parseISO'
import shortid from 'shortid';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames'
import differenceInDays from 'date-fns/differenceInDays'

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'
import ReservationContext from '../../contexts/reservation/reservation.context'

import LoadingScreen from '../loading-screen/LoadingScreen'
import { RowContainer, ColContainer, MoreButton } from "./listStyles"
import EmptyList from '../empty-list/EmptyList'

import Eye from '../../images/svg/eye.svg'

const List = () => {
    const { reservations, setReservation } = useContext(ReservationAdminContext)
    const { isLoading } = useContext(ReservationContext)

    const handleSetReservation = (reservation) => setReservation(reservation)

    if (isLoading) return <LoadingScreen />

    if (isEmpty(reservations)) return <EmptyList />

    return (
        reservations.map((reservation) => {
            const { id, billing, room, start_date, end_date, reservation_status_id } = reservation
            const year = getYear(parseISO(start_date))
            const { lastname, firstname, email, phone } = JSON.parse(billing)
            const time = differenceInDays(parseISO(end_date), parseISO(start_date))

            return (
                <RowContainer key={shortid.generate()}>

                    <ColContainer
                        xs={4}
                        sm={2}
                        className={classNames({
                            'pending': reservation_status_id === 1,
                            'accepted': reservation_status_id === 2,
                            'declined': reservation_status_id === 3,
                            'archived': reservation_status_id === 4
                        })}
                    >
                        {`${year}-${id < 10 ? `0${id}` : id}`}
                    </ColContainer>

                    <ColContainer xs={4} md={3}>{`${lastname} ${firstname}`}</ColContainer>

                    <ColContainer xs={3} className="d-none d-md-flex">

                        <ColContainer>{email}</ColContainer>

                        <ColContainer>{phone}</ColContainer>

                    </ColContainer>

                    <ColContainer xs={2} md={1}>{room}</ColContainer>

                    <ColContainer xs={2} md={1} className="d-none d-sm-flex">{`${time} nap`}</ColContainer>

                    <ColContainer xs={2} className="flex-row">

                        <MoreButton
                            variant="outline-secondary"
                            onClick={() => handleSetReservation(reservation)}
                        >

                            <Eye />

                        </MoreButton>

                    </ColContainer>

                </RowContainer>
            )
        })
    )
}

export default List