import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const EmptyList = () => (
    <Container>

        <Row>

            <Col className="text-center text-bold py-5">Nincs foglalás ebben a kategóriában</Col>

        </Row>

    </Container>
)

export default EmptyList