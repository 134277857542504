import React from 'react'
import PropTypes from 'prop-types'
import { validateEmail } from '../../utils/helper'

import Button from 'react-bootstrap/Button'

const MailTo = ({ children, email, variant }) => {
    if (! validateEmail(email))
    {
        return null
    }

    const handleMailTo = () => window.location.href = `mailto:${ email }`

    return <Button color="link" variant={ variant } onClick={ handleMailTo }>{ children }</Button>
}

MailTo.propTypes = {
    email: PropTypes.string.isRequired,
    variant: PropTypes.string
}

export default MailTo
