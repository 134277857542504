import styled from '@emotion/styled'
import {css} from '@emotion/react'

export const borderStyle = css`
    border-bottom: .0625rem solid var(--light-gray);
`

export const grayBgStyle = css`
    background-color: var(--light-gray);
`

export const RoomNumber = styled.div`
    font-size: 2rem;
    padding: 1.5rem;
    border-bottom: .0625rem solid var(--gold);

    span {
        border: .1875rem solid var(--gold);
        border-radius: 100%;
        padding: .1875rem;
        display: inline-flex;
        width: 3.125rem;
        height: 3.125rem;
        justify-content: center;
        align-items: center;
        color: var(--gold);
    }
`

export const Icon = styled.span`
    margin-right: .625rem;
    display: inline-flex;

    svg {
        height: 1.25rem;
        fill: var(--black);
    }
`

export const BottomBorder = styled.span`
    border-bottom: .0625rem solid var(--gold);
    padding-bottom: .3125rem;
`