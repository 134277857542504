import React, { useContext } from 'react'

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'

import { RowContainer, ColContainer } from "../list/listStyles"

const ListHeader = () => {
    const { changeOrder } = useContext(ReservationAdminContext)

    return (
        <RowContainer className="header-row">

            <ColContainer xs={4} sm={2} onClick={changeOrder} className="header-col">azonosító</ColContainer>

            <ColContainer xs={4} md={3} onClick={changeOrder} data-orderby="billing.lastname">név</ColContainer>

            <ColContainer xs={3} onClick={changeOrder} className="d-none d-md-flex">elérhetőség</ColContainer>

            <ColContainer xs={2} md={1} onClick={changeOrder} data-orderby="room">szoba</ColContainer>

            <ColContainer xs={2} md={1} className="d-none d-sm-flex">idő</ColContainer>

            <ColContainer xs={2} onClick={changeOrder}></ColContainer>

        </RowContainer>
    )
}

export default ListHeader