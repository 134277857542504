import React, { useContext } from 'react'

import AdminContext from '../../contexts/admin/admin.context'

import ReservationHeader from '../reservation-header/ReservationHeader'
import ReservationsList from '../reservations-list/ReservationsList'

const ReservationAdmin = () => {
    const { auth: { user: { name } }, logout } = useContext(AdminContext)

    return (
        <>
        
            <ReservationHeader userName={ name } logout={ logout } />

            <ReservationsList />

        </>
    )
}

ReservationAdmin.propTypes = {}

export default ReservationAdmin