import React from 'react'

import ReservationInfoHeader from '../reservation-info-header/ReservationInfoHeader'
import ReservationInfoBody from '../reservation-info-body/ReservationInfoBody'
import ReservationInfoFooter from '../reservation-info-footer/ReservationInfoFooter'
import { ReservationInfoContainer } from "./reservationInfoStyles"

const ReservationInfo = () => (
    <ReservationInfoContainer>

        <ReservationInfoHeader />

        <ReservationInfoBody />

        <ReservationInfoFooter />

    </ReservationInfoContainer>
)

export default ReservationInfo