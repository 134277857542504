import styled from '@emotion/styled'

import Button from 'react-bootstrap/Button'

export const Header = styled.header`
    background-color: var(--gold);
    color: var(--black);

    &.margin-minus {
        margin: -1.25rem -1.25rem 0 -1.25rem;
    }

    .container-fluid {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    svg {
        height: 1.25rem;
        fill: var(--dark);
    }
`

export const HeaderTitle = styled.span`
    color: white;

    span {
        border: .0625rem solid white;
        padding: .1875rem .625rem;
    }
`

export const CloseButton = styled(Button)`
    svg {
        height: 1.25rem;
        fill: white;
    }

    &:hover {
        svg {
            fill: var(--gold);
        }
    }
`