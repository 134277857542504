import React, { useContext } from 'react'

import AdminContext from '../../contexts/admin/admin.context'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FormInput from '../form-input/FormInput'
import { LoginPage, Title, FormContainer } from "./loginFormStyles"

const LoginForm = () => {
    const { auth: { login: { email, password } }, setLoginData, login } = useContext(AdminContext)

    return (
        <LoginPage>

            <Title className="h3 m-0">adminisztráció</Title>

            <FormContainer onSubmit={login}>

                <Row className="justify-content-center">

                    <Col md={7}>

                        <FormInput
                            label="e-mail"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={setLoginData}
                        />

                        <FormInput
                            label="jelszó"
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={setLoginData}
                        />

                        <Button className="w-100" variant="outline-dark" type="submit">belépés</Button>

                    </Col>

                </Row>

            </FormContainer>

        </LoginPage>
    )
}

LoginForm.propTypes = {}

export default LoginForm