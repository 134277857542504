import React, { useContext, useEffect, useState } from 'react'
import { TOMORROW } from '../../utils/helper'
import apiClient from '../../utils/apiClient'
import union from 'lodash/union'
import isEmpty from 'lodash/isEmpty'
import { getDatesBetween } from '../../utils/helper'

import { DateRangePicker } from "react-date-range"
import { hu } from "date-fns/locale"
import format from 'date-fns/format'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import ReservationContext from '../../contexts/reservation/reservation.context'

import HeaderContainer from '../header-container/HeaderContainer'
import { HeaderTitle, CloseButton } from "../header-container/headerContainerStyles"
import { ReservationInfoContainer } from "../reservation-info/reservationInfoStyles"
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from "react-bootstrap/Alert"

import Xmark from '../../images/svg/xmark.svg'
import FormControlSelect from '../form-input/FormControlSelect'

const initReservation = {
    room: 0,
    dateRange: {
        startDate: TOMORROW,
        endDate: TOMORROW,
        key: 'selection'
    }
}

const ManualReservation = () => {
    const { setManualReservation, loading, setLoading } = useContext(ReservationContext)
    
    const [reservation, setReservation] = useState(initReservation)
    const [reservedDates, setReservedDates] = useState([])
    const [notification, setNotification] = useState({
        variant: null,
        message: '',
    })

    useEffect(() => {
        if (isEmpty(notification.variant)) return

        setTimeout(() => {
            setNotification({
                variant: null,
                message: '',
            })
        }, 3000);
    }, [notification])

    useEffect(async () => {
        if (reservation.room === 0) return

        setLoading({
            isLoading: true,
            text: 'Elérhető időpontok frissítése!'
        })

        setReservedDates([])

        try {
            const response = await apiClient.get(`${process.env.API_URL}static/reservation/${reservation.room}`)
            const { data: { dates } } = await response

            if (!isEmpty(dates)) {
                let reservedDates = []

                await dates.map((dateInterval) => {
                    const newDates = getDatesBetween(dateInterval[0], dateInterval[1])
                    // TODO: unique dates???
                    reservedDates = union(reservedDates, newDates)
                })

                setReservedDates(reservedDates)
            }
        }
        catch (error) {
            const { data: { message } } = await error.response

            console.log(message)
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }, [reservation.room])

    const handleClose = () => setManualReservation(false)

    const handleSelect = ({ selection }) => setReservation({ ...reservation, dateRange: selection })

    const handleOnChange = ({ target: { value } }) => setReservation({ ...initReservation, room: parseInt(value) })

    const closeAlert = () => setNotification({ variant: null, message: '' });

    const storeReservation = async () => {
        setLoading({
            isLoading: true,
            text: 'Időpont rögzítése...'
        })

        const newData = {
            ...reservation,
            dateRange: {
                ...reservation.dateRange,
                startDate: format(reservation.dateRange.startDate, 'yyyy-MM-dd'),
                endDate: format(reservation.dateRange.endDate, 'yyyy-MM-dd')
            }
        }

        try {
            const response = await apiClient.post(`${process.env.API_URL}reservations`, newData)
            const { data: { message } } = await response

            setNotification({
                variant: 'success',
                message,
            })

            setReservation(initReservation)
        }
        catch (error) {
            const { data: { message } } = await error.response

            setNotification({
                variant: 'danger',
                message: status === 400 ? 'A csillaggal jelölt mezők kitöltése kötelező' : message,
            })
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        storeReservation()
    }
    
    return (
        <Container>

            <ReservationInfoContainer>

                <HeaderContainer>

                    <Col xs={10}>

                        <HeaderTitle className="h4">foglalás rögzítése</HeaderTitle>

                    </Col>

                    <Col xs={2} className="d-flex justify-content-end">

                        <CloseButton onClick={handleClose} variant="outline-light">

                            <Xmark />

                        </CloseButton>

                    </Col>

                </HeaderContainer>

                {!loading.isLoading && (
                    <div className="my-5 py-5">

                        <Form onSubmit={handleSubmit}>

                            <Container>

                                <Row>

                                    <Col sm="6">

                                        <Row >

                                            <Col sm="12">

                                                <FormControlSelect
                                                    options={{
                                                        0: "válasszon",
                                                        1: "#1 szoba",
                                                        2: "#2 szoba",
                                                        3: "#3 szoba",
                                                        4: "#4 szoba",
                                                        5: "vendégház"
                                                    }}
                                                    onChange={handleOnChange}
                                                    value={reservation.room}
                                                />

                                            </Col>

                                            <Col sm="12">

                                                <Button variant="outline-success" className="w-100" type="submit">mentés</Button>

                                            </Col>

                                            {!isEmpty(notification.variant) && (

                                                <Col sm="12" className="mt-4">

                                                    <Alert variant={notification.variant} onClose={closeAlert} dismissible>{notification.message}</Alert>

                                                </Col>

                                            )}

                                        </Row>
                                    
                                    </Col>

                                    <Col sm="6">
                                    
                                        <DateRangePicker
                                            rangeColors={['#c9a96a']}
                                            ranges={[reservation.dateRange]}
                                            onChange={handleSelect}
                                            locale={hu}
                                            dateDisplayFormat="yyyy. MMMM d."
                                            minDate={TOMORROW}
                                            renderStaticRangeLabel={() => { }}
                                            staticRanges={[]}
                                            inputRanges={[]}
                                            showDateDisplay={false}
                                            weekStartsOn={1}
                                            disabledDates={reservedDates}
                                        />

                                    </Col>

                                </Row>

                            </Container>

                        </Form>

                    </div>
                )}

            </ReservationInfoContainer>

        </Container>
    )
}

export default ManualReservation