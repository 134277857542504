import React, { useContext } from 'react'
import getYear from 'date-fns/getYear'
import parseISO from 'date-fns/parseISO'

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'

import Col from 'react-bootstrap/Col'
import HeaderContainer from '../header-container/HeaderContainer'
import { HeaderTitle, CloseButton } from "../header-container/headerContainerStyles"

import Xmark from '../../images/svg/xmark.svg'

const ReservationInfoHeader = () => {
    const { reservation: { id, start_date }, setReservation } = useContext(ReservationAdminContext)
    const year = getYear(parseISO(start_date))
    
    const handleClose = () => setReservation({})

    return (
        <HeaderContainer className="m-0">

            <Col xs={10}>

                <HeaderTitle className="h4">
                    <span>{ `${year}-${id < 10 ? `0${id}` : id}` }</span>
                    {' '}
                    foglalás adatai
                </HeaderTitle>

            </Col>

            <Col xs={2} className="d-flex justify-content-end">

                <CloseButton onClick={handleClose} variant="outline-light">

                    <Xmark />

                </CloseButton>

            </Col>

        </HeaderContainer>
    )
}

export default ReservationInfoHeader