import React from 'react'
import PropTypes from 'prop-types'

import { Header } from "./headerContainerStyles"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const HeaderContainer = ({children, className}) => {
    return (
        <Header className={className}>

            <Container fluid>

                <Row className="justify-content-between align-items-center">
            
                    {children}

                </Row>

            </Container>

        </Header>
    )
}

HeaderContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default HeaderContainer