import React, { useContext, useEffect, useState } from 'react'
import apiClient from '../../utils/apiClient'

import ReservationContext from '../../contexts/reservation/reservation.context'

import HeaderContainer from '../header-container/HeaderContainer'
import { HeaderTitle, CloseButton } from "../header-container/headerContainerStyles"
import { ReservationInfoContainer } from "../reservation-info/reservationInfoStyles"
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import Xmark from '../../images/svg/xmark.svg'
import MoneyRow from '../money-row/MoneyRow'

const MoneyChange = () => {
    const { setMoneyChange, loading, setLoading } = useContext(ReservationContext)

    const [prices, setPrices] = useState([])

    useEffect(async () => {
        setLoading({
            isLoading: true,
            text: 'árak letöltése...'
        })

        try {
            const response = await apiClient.get(`${process.env.API_URL}prices`)
            const { data } = await response

            setPrices(data)
        }
        catch (error) {
            const { data: { message } } = await error.response

            console.log(message)
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }, [])

    const handleClose = () => setMoneyChange(false)
    // TODO: email templates
    // TODO: emial sending on status changes

    const handleOnChange = async (price) => {
        const index = await prices.findIndex(({ id }) => id === price.id)

        if (index !== -1) {
            const newPrices = await [
                ...prices.slice(0, index),
                Object.assign({}, prices[index], price),
                ...prices.slice(index + 1)
            ]
            setPrices(newPrices)
        }
    }

    const updatePrices = async () => {
        setLoading({
            isLoading: true,
            text: 'árak frissítése folyamatban...'
        })

        try {
            const response = await apiClient.post(`${process.env.API_URL}prices`, prices)
            const { data } = await response

            setPrices(data)
        }
        catch (error) {
            const { data: { message } } = await error.response

            console.log(message)
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        updatePrices()
    }

    return (
        <ReservationInfoContainer>

            <HeaderContainer>

                <Col xs={10}>

                    <HeaderTitle className="h4">árak szerkesztése</HeaderTitle>

                </Col>

                <Col xs={2} className="d-flex justify-content-end">

                    <CloseButton onClick={handleClose} variant="outline-light">

                        <Xmark />

                    </CloseButton>

                </Col>

            </HeaderContainer>

            {!loading.isLoading && (
                <div className="my-5 py-5">

                    <Form onSubmit={handleSubmit}>

                        <Container>

                            <Row>

                                <Col>személyek száma</Col>

                                <Col>ár HUF</Col>

                                <Col>ár EUR</Col>

                            </Row>

                            {prices.map((price) => <MoneyRow key={price.id} item={price} update={handleOnChange} />)}

                            <Row>

                                <Col xs={{ span: 6, offset: 3 }}>

                                    <Button type="submit" className="w-100" variant="outline-success">mentés</Button>

                                </Col>

                            </Row>

                        </Container>

                    </Form>

                </div>
            )}

        </ReservationInfoContainer>
    )
}

export default MoneyChange