import React, { useState, useEffect } from "react"
import apiClient from '../../utils/apiClient'
import isEmpty from 'lodash/isEmpty'

import ReservationContext, { initLoading } from '../../contexts/reservation/reservation.context'
import AdminContext, { initAuth } from '../../contexts/admin/admin.context'

import LoadingScreen from '../../components/loading-screen/LoadingScreen'

import '../../scss/app.scss'
import LoginForm from "../../components/login-form/LoginForm"
import ReservationAdmin from '../../components/reservation-admin/ReservationAdmin'

import '../../scss/app.scss'

const Admin = () => {
    const [loading, setLoading] = useState(initLoading)
    const [auth, setAuth] = useState(initAuth)
    const [moneyChange, setMoneyChange] = useState(false)
    const [manualReservation, setManualReservation] = useState(false)
    const [moneyPrepareToUpdate, setMoneyPrepareToUpdate] = useState(false)

    const getUser = async () => {
        try
        {
            const response = await apiClient.get(`${process.env.API_URL}user`)
            const { data } = await response

            setAuth({ ...auth, user: data })
        }
        catch (error)
        {
            const { data: { message } } = await error.response

            console.error(message)
        }
    }

    useEffect(async () => {
        const { status } = await apiClient.get(`${process.env.API_DOMAIN_URL}sanctum/csrf-cookie`)

        if (status === 204)
        {
            getUser()
        }
    }, [])

    const login = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        try
        {
            const response = await apiClient.post(`${process.env.API_DOMAIN_URL}login`, auth.login)
            const { data } = await response

            setAuth({ ...initAuth })
            getUser()
        }
        catch (error)
        {
            const response = await error.response

            console.error(response)
        }
    }

    const logout = async () => {
        try
        {
            await apiClient.post(`${process.env.API_DOMAIN_URL}logout`)
        }
        catch (error)
        {
            const response = await error.response

            console.error(response)
        }

        setAuth(initAuth)
    }

    const setLoginData = ({ target: { name, value } }) => {
        setAuth({ ...auth, login: { ...auth.login, [name]: value } })
    }

    const providedData = {
        loading,
        setLoading,
        moneyChange,
        manualReservation,
        setMoneyChange,
        moneyPrepareToUpdate,
        setMoneyPrepareToUpdate,
        setManualReservation
    }

    const providedAuthData = {
        auth,
        setLoginData,
        login,
        logout
    }

    return (
        <>
        
            <div id="wrapper">

                <main className="admin">

                        <ReservationContext.Provider value={providedData}>

                            <AdminContext.Provider value={providedAuthData}>
                            
                                { isEmpty(auth.user) ? <LoginForm /> : <ReservationAdmin /> }

                            </AdminContext.Provider>

                        </ReservationContext.Provider>

                </main>

            </div>

            { loading.isLoading && <LoadingScreen text={loading.text} /> }

        </>
    )
}

export default Admin
