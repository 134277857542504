import React from 'react'
import PropTypes from 'prop-types'

import Button from 'react-bootstrap/Button'

const PhoneTo = ({ children, phone, variant }) => {
    const handlePhoneTo = () => window.location.href = `tel:${ phone.replace(/-/g, '') }`

    return <Button color="link" variant={ variant } onClick={ handlePhoneTo }>{ children }</Button>
}

PhoneTo.propTypes = {
    phone: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default PhoneTo
