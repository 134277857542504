import React, { useContext, useEffect, useState } from 'react'
import apiClient from '../../utils/apiClient'
import union from 'lodash/union'
import isEmpty from 'lodash/isEmpty'

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'
import ReservationContext from '../../contexts/reservation/reservation.context'

import Container from 'react-bootstrap/Container';
import StatusFilter from '../status-filter/StatusFilter'
import ListHeader from '../list-header/ListHeader'
import List from '../list/List'
import ReservationInfo from '../reservation-info/ReservationInfo'
import MoneyChange from '../money-change/MoneyChange'
import ManualReservation from '../manual-reservation/ManualReservation'

const ReservationsList = () => {
    const { setLoading, moneyChange, manualReservation } = useContext(ReservationContext)

    const [reservations, setReservations] = useState([])
    const [reservation, setReservation] = useState({})
    const [statuses, setStatuses] = useState([{id: 0, name: 'összes'}])
    const [status, setStatus] = useState(0)

    useEffect(() => {
        if (isEmpty(reservations)) return

        const reservationGetParam = window.location.search.substring(1)

        if (reservationGetParam.indexOf('foglalas') === -1) return

        const [ txt, id ] = reservationGetParam.split('-')

        if (isEmpty(id)) return

        const selectedReservation = reservations.filter((reservation) => reservation.id === parseInt(id))[0]

        setReservation(selectedReservation)
    }, [reservations])

    useEffect(async () => {
        setLoading({
            isLoading: true,
            text: 'Foglalások letöltése...'
        })
        setReservations([])

        try
        {
            const response = await apiClient.get(`${process.env.API_URL}reservations${status === 0 ? '' : `/${status}`}`)
            const { data } = await response

            if (!isEmpty(data))
            {
                setReservations(data)
            }
        }
        catch (error)
        {
            const { data } = await error.response

            console.error(data)
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }, [status])
    
    const getStatuses = async () => {
        try
        {
            const response = await apiClient.get(`${process.env.API_URL}statuses`)
            const { data } = await response

            setStatuses(union(statuses, data))
        }
        catch (error)
        {
            const { data } = await error.response

            console.error(data)
        }
    }

    const changeReservationStatus = async (id, status) => {
        // TODO: backend statuschange response message |id && newstatus|? -> frontend alert?
        try
        {
            const response = await apiClient.post(`${process.env.API_URL}reservations/${id}/status/${status}`)
            const { data } = await response

            const newReservations = reservations.map((reservation) => {
                if (reservation.id === data.id) return data

                return reservation
            }).filter((reservation) => reservation.reservation_status_id !== status)

            setReservations(newReservations)
            setReservation({})
            setStatus(0)
        }
        catch (error)
        {
            const { data } = await error.response

            console.error(data)
        }
    }

    const changeOrder = ({ target: { dataset: { orderby } } }) => {
        // TODO: order valtas? order indicator!
    }

    const providedData = {
        reservations,
        reservation,
        statuses,
        status,
        setReservation,
        getStatuses,
        setStatus,
        changeReservationStatus,
        changeOrder,
    }

    return (
        <>

            <ReservationAdminContext.Provider value={providedData}>

                <Container fluid>

                    <StatusFilter />

                    <ListHeader />

                    <List />

                </Container>

                { !isEmpty(reservation) && <ReservationInfo /> }

                { moneyChange && <MoneyChange /> }

                { manualReservation && <ManualReservation /> }
            
            </ReservationAdminContext.Provider>

        </>
    )
}

ReservationsList.propTypes = {}

export default ReservationsList