import styled from '@emotion/styled'
import { css } from '@emotion/react';

import Button from 'react-bootstrap/Button'

const buttonStyles = css`
    svg {
        fill: white;
        height: 1.25rem;
    }
`

export const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: var(--light-gray);

    .container-fluid {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
`

export const AcceptButton = styled(Button)`
    ${buttonStyles}
`

export const DeclineButton = styled(Button)`
    ${buttonStyles}
`