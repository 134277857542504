import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classnames from 'classnames'

import ReservationAdminContext from '../../contexts/reservation/reservation-admin.context'

import { StatusContainer, Status } from "./statusFilterStyles"

const StatusFilter = () => {
    const { getStatuses, setStatus, statuses, status } = useContext(ReservationAdminContext)

    useEffect(() => {
        getStatuses()
    }, [])

    const handleStatusChange = ({ target: { dataset: { id } } }) => setStatus(parseInt(id))

    return (
        <StatusContainer>

            { statuses.map(({ id, name }) => (
                <Status
                    key={shortid.generate()}
                    className={classnames({'active': status === id})}
                    onClick={handleStatusChange}
                    data-id={id}
                >

                    {name}
                    
                </Status>
            )) }

        </StatusContainer>
    )
}

StatusFilter.propTypes = {}

export default StatusFilter