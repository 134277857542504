import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import ReservationContext from '../../contexts/reservation/reservation.context'

import HeaderContainer from '../header-container/HeaderContainer'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import MoneyChange from '../../images/svg/money-change.svg'
import Calendar from '../../images/svg/calendar-days.svg'

const ReservationHeader = ({ userName, logout }) => {
    const { setMoneyChange, setManualReservation } = useContext(ReservationContext)

    const openMoneyChange = () => setMoneyChange(true)

    const openManualReservation = () => setManualReservation(true)
    
    return (
        <HeaderContainer className="margin-minus">

            <Col xs={ 3 }>
            
                <span>{ userName }</span>

            </Col>

            <Col xs={1}>

                <Button variant="outline-warning">
        
                    <MoneyChange onClick={openMoneyChange} />

                </Button>

            </Col>

            <Col xs={1}>

                <Button variant="outline-warning">
        
                    <Calendar onClick={openManualReservation} />

                </Button>

            </Col>

            <Col xs={3} className="d-flex justify-content-end">

                <Button variant="outline-dark" onClick={logout}>kilépés</Button>
            
            </Col>

        </HeaderContainer>
    )
}

ReservationHeader.propTypes = {
    logout: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired
}

export default ReservationHeader