import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const buttonStyles = css`
    svg {
        height: 1rem;
    }

    &:hover {
        svg {
            fill: white;
        }
    }
`

export const RowContainer = styled(Row)`
    margin-bottom: .1875rem;

    &:not(.header-row):nth-of-type(even) {
        background-color: lightgray;
    }

    &.header-row {
        border-bottom: .0625rem solid white;
    }
`

export const ColContainer = styled(Col)`
    padding: .1875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.col-4:not(.header-col):first-of-type {
        position: relative;

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: .625rem;
            background-color: lightcoral;
        }

        &.accepted::before {
            background-color: var(--status-green);
        }

        &.decline::before {
            background-color: var(--status-red);
        }

        &.pending::before {
            background-color: var(--status-yellow);
        }

        &.archived::before {
            background-color: var(--status-gray);
        }
    }
`

export const MoreButton = styled(Button)`
    ${buttonStyles}

    svg {
        fill: var(--secondary);
    }
`