import { createContext } from 'react'

const ReservationAdminContext = createContext({
    reservations: [],
    reservation: {},
    statuses: [
        { id: 0, name: 'összes' }
    ],
    status: 0,
    getReservations: () => { },
    setReservation: () => { },
    getStatuses: () => { },
    setStatus: () => { },
    changeOrder: () => { }
})

export default ReservationAdminContext
